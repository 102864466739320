import { Component } from '@angular/core';
import { NgClass } from '@angular/common';
import { ChatWidgetComponent } from '../shared/components/chat-widget/chat-widget.component';
import {
  ChatWidgetFloatingIconComponent
} from '../shared/components/chat-widget-floating-icon/chat-widget-floating-icon.component';

@Component({
  selector: 'docugenai-chatbot',
  templateUrl: './docugenai-chatbot.component.html',
  styleUrls: [
    './docugenai-chatbot.component.scss'
  ],
  imports: [
    NgClass,
    ChatWidgetComponent,
    ChatWidgetFloatingIconComponent,
    ChatWidgetComponent,
    ChatWidgetFloatingIconComponent,
  ],
})
export class DocugenaiChatbotComponent {
  isOpen: boolean = false;

  setChatOpen() {
    this.isOpen = true;
  }

  setChatClosed() {
    this.isOpen = false;
  }
}
