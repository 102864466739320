<div class="flex flex-row items-center justify-between">
  <div
    tabindex="0"
    role="button"
    class="h-14 space-x-3 bg-bosch-blue-50 rounded-lg shadow-lg transition hover:brightness-50 hover:cursor-pointer px-3 py-2.5"
  >
    <div class="flex flex-col items-center justify-between">
      <span class="text-xs text-white">{{'chatBot.question' | translate }}</span>
      <span class="text-xs text-white font-bold">{{'chatBot.ask' | translate }}</span>
    </div>
  </div>
  <img
    src="https://sddevstwesteu001.blob.core.windows.net/sd-ui/ai-copilot-icon.png"
    alt="AI Copilot Icon"
    class="h-14 aspect-square rounded-full shadow mx-3"
  />
</div>

