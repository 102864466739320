import { createReducer, on } from '@ngrx/store';
import {
  askAssistant,
  assistantResponded,
  clearChat,
  setChatWindowVisibility,
} from '../actions/chatbot.action';
import {
  IChatbotState,
  IStateObjectStatus,
} from '../state/chatbot.state.model';

export const docugenAiInitialState: IChatbotState = {
  conversation: {
    data: {
      messages: [],
      totalMessages: 0,
    },
    status: 'loaded' as IStateObjectStatus,
  },
  windowOpened: false,
};

export const chatbotReducer = createReducer(
  docugenAiInitialState,
  on(clearChat, (state) => ({
    ...state,
    conversation: {
      data: {
        messages: [],
        totalMessages: 0,
      },
      status: 'loaded' as IStateObjectStatus,
    },
  })),
  on(askAssistant, (state, { payload }) => {
    const messages = [...state.conversation.data.messages, payload];

    return {
      ...state,
      conversation: {
        data: {
          messages,
          totalMessages: messages.length,
        },
        status: 'loading' as IStateObjectStatus,
      },
    };
  }),
  on(assistantResponded, (state, { payload }) => ({
    ...state,
    conversation: {
      data: {
        messages: payload.messages,
        totalMessages: payload.totalMessages,
      },
      status: 'loaded' as IStateObjectStatus,
    },
  })),
  on(setChatWindowVisibility, (state, { payload }) => ({
    ...state,
    windowOpened: payload,
  }))
);
