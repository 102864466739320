import { Component } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-chat-widget-floating-icon',
  standalone: true,
  imports: [
    TranslatePipe,
  ],
  templateUrl: './chat-widget-floating-icon.component.html',
  styleUrls: [
    './chat-widget-floating-icon.component.scss',
    '../../styles/style.scss'
  ],
})
export class ChatWidgetFloatingIconComponent {}
