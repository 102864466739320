<div class="w-full mt-2">
  <div class="grid grid-cols-1 sm:grid-cols-1 gap-2 mb-2">
    @for (eUserOption of userOptions(); track eUserOption) {
      <div
        tabindex="0"
        class="a-chip px-3"
        role="button"
        aria-labelledby="chip-label-id-default"
        (click)="onOptionClicked(eUserOption)"
        [style.color]="'white'"
        [style.background-color]="eUserOption.color"
        [style.max-width]="'fit-content'"
      >
        <span id="chip-label-id-default" class="a-chip__label">
          {{ eUserOption.content }}
        </span>
      </div>
    }
  </div>
</div>
