import { Component, input, output } from '@angular/core';
import { IUserOption } from '../../models';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-chat-user-options',
  standalone: true,
  imports: [],
  templateUrl: './chat-user-options.component.html',
  styleUrls: [
    './chat-user-options.component.scss',
    '../../styles/style.scss',
  ],
})
export class ChatUserOptionsComponent {
  userOptions = input.required<NonNullable<IUserOption[]>>();
  optionClicked = output<IUserOption>();
  onOptionClicked(option: IUserOption): void {
    this.optionClicked.emit(option);
  }
}
