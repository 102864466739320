<div id="chatbot">
  @if (isOpen) {
    <div
      class="fixed bottom-8 right-0 z-10 p-4 xl:pb"
      [ngClass]="{'h-[calc(100vh-3rem)] w-full md:w-2/3 lg:w-1/2 xl:w-5/12': isOpen}"
    >
      <app-chat-widget
        (closeClicked)="setChatClosed()"
        (minimizeClicked)="setChatClosed()"
        (keydown.escape)="setChatClosed()"
      ></app-chat-widget>
    </div>
  } @else {
    <div class="fixed bottom-12 right-8">
      <app-chat-widget-floating-icon
        (click)="setChatOpen()"
        (keydown.enter)="setChatOpen()"
      ></app-chat-widget-floating-icon>
    </div>
  }
</div>

