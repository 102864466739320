<div
  class="w-full h-full rounded shadow-lg overflow-hidden flex flex-col bg-white"
  [ngStyle]="{ display: isLoading ? 'none' : 'flex' }"
>
  <div
    class="h-12 flex-initial shadow flex justify-between items-center py-2.5 px-3 bg-bosch-blue-50"
  >
    <div class="flex items-center space-x-3 h-full">
      <img
        src="https://sddevstwesteu001.blob.core.windows.net/sd-ui/ai-copilot-icon.png"
        alt="AI Copilot Icon"
        class="h-full aspect-square rounded-full shadow"
      />
      <p class="font-bold text-lg text-white text-bold">{{ "chatBot.name" | translate }}</p>
    </div>

    <div class="space-x-3">
      <button
        style="margin-right: 1rem;"
        class="rounded a-button a-button--integrated -without-label"
        (click)="onMinimizeButtonClick()"
      >
        <i class="a-icon ui-ic-down text-sm text-white"></i>
      </button>

      <button
        class="rounded a-button a-button--integrated -without-label"
        (click)="onCloseButtonClick()"
      >
        <i class="a-icon ui-ic-close text-sm text-white"></i>
      </button>
    </div>
  </div>
  <div
    #chatContainer
    class="flex-content p-3 pt-5 overflow-y-auto overflow-x-hidden flex flex-col space-y-3 relative"
  >
    <div class="flex space-x-2 items-end">
      <img
        src="https://sddevstwesteu001.blob.core.windows.net/sd-ui/ai-copilot-icon.png"
        alt="AI Copilot Icon"
        class="h-8 aspect-square rounded-full shadow"
      />

      <div class="w-full">
        <div
          class="px-4 py-3 rounded-lg rounded-bl-none bg-bosch-gray-95 inline-block"
        >
          <p class="text-sm">
            {{ "chatBot.greet" | translate }}
          </p>
        </div>
      </div>
    </div>

    <div class="flex space-x-2 items-end">
      <img
        src="https://sddevstwesteu001.blob.core.windows.net/sd-ui/ai-copilot-icon.png"
        alt="AI Copilot Icon"
        class="h-8 aspect-square rounded-full shadow invisible"
      />

      <div class="w-full">
        <app-chat-user-options
          [userOptions]="userOptions"
          (optionClicked)="onOptionClicked($event)"
        ></app-chat-user-options>
      </div>

    </div>

    @for (message of messages$ | async; track message) {
      <div
        class="flex space-x-2 items-end"
        [ngClass]="message.role === IMessageRole.USER ? 'justify-end' : ''"
      >
        @if (message.role === IMessageRole.ASSISTANT) {
          <img
            src="https://sddevstwesteu001.blob.core.windows.net/sd-ui/ai-copilot-icon.png"
            alt="AI Copilot Icon"
            class="h-8 aspect-square rounded-full shadow"
          />
        } @else {
          <ng-container *ngTemplateOutlet="userProfile"></ng-container>
        }
        <div class="message--content px-4 py-3 rounded-lg rounded-bl-none bg-bosch-gray-95 inline-block"
             [innerHTML]="formatResponse(message) | formatText"></div>
      </div>
    }

    @if (loadingStatus() === 'loading') {
      <div class="flex space-x-2 items-end">
        <img
          src="https://sddevstwesteu001.blob.core.windows.net/sd-ui/ai-copilot-icon.png"
          alt="AI Copilot Icon"
          class="h-8 aspect-square rounded-full shadow"
        />

        <div
          class="px-3 rounded-lg rounded-bl-none animate-pulse w-44 max-w-full"
        >
          <div class="a-activity-indicator -small" aria-live="off">
            <div class="a-activity-indicator__top-box"></div>
            <div class="a-activity-indicator__bottom-box"></div>
          </div>
        </div>
      </div>
    } @else if (loadingStatus() === 'error') {
      <div class="flex space-x-2 items-end">
        <img
          src="https://sddevstwesteu001.blob.core.windows.net/sd-ui/ai-copilot-icon.png"
          alt="AI Copilot Icon"
          class="h-8 aspect-square rounded-full shadow"
        />

        <div
          class="px-3 py-2 rounded-lg rounded-bl-none bg-bosch-red-25 text-white"
        >
          <p class="text-sm">{{ "chatBot.error" | translate }}</p>
        </div>
      </div>
    }
  </div>

  <div class="h-14 flex-initial shadow-top flex items-center px-2">
    <input
      #userMessageInput
      type="text"
      class="flex-1 px-3 py-2 rounded-l-md text-sm focus:outline-none"
      placeholder="Type a message..."
      [formControl]="userMessage"
      (keyup.enter)="send(userMessage.value)"
    />

    <button
      class="px-4 h-10 hover:bg-bosch-blue-40 transition-colors focus:outline-none focus:ring-2 focus:ring-bosch-blue-50 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
      (click)="send(userMessage.value)"
      [disabled]="loadingStatus() === 'loading'"
    >
      <i class="boschicon-bosch-ic-paperplane text-lg" [style.font-size]="'24px'"></i>
    </button>
  </div>
</div>
<ng-template #userProfile>
  <i class="a-icon boschicon-bosch-ic-my-brand-frame chat-icon" title="user-profile"></i>
</ng-template>
