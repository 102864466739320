import { CommonModule, NgClass } from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  effect,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  Signal,
  ViewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../../shared/data/state/app.state.model';
import { IUserOption } from '../../models';
import { ChatUserOptionsComponent } from '../chat-user-options/chat-user-options.component';
import { TranslatePipe } from '@ngx-translate/core';
import { GlboalAiChatService } from 'src/app/shared/services/chatbot.service';
import { finalize, tap } from 'rxjs';
import { FormatTextPipe } from '../../../../../../../shared/pipes/format-text.pipe';
import { DocumentApiService } from '../../services/document-api.service';
import { LoadingIndicatorService } from 'src/app/components';
import { IChatbotMessage } from '../../../../../../../shared/data/state/chatbot.state.model';


enum IMessageRole {
  ASSISTANT = 'assistant',
  USER = 'user',
}

@Component({
  selector: 'app-chat-widget',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    MatProgressBarModule,
    ChatUserOptionsComponent,
    TranslatePipe,
    CommonModule,
    FormatTextPipe
  ],
  templateUrl: './chat-widget.component.html',
  styleUrls: ['./chat-widget.component.scss', '../../styles/style.scss'],
})
export class ChatWidgetComponent implements AfterViewChecked, AfterViewInit {
  IMessageRole = IMessageRole;
  @Output() closeClicked = new EventEmitter<void>();
  @Output() minimizeClicked = new EventEmitter<void>();

  userMessage = new FormControl('');
  messages: Signal<IChatbotMessage[] | undefined>;
  loadingStatus: Signal<string | undefined>;
  toScrollDown = false;
  userName: string | undefined;
  userOptions: IUserOption[] = [
    {
      content: 'Repair Instruction',
      color: "#008B8B"
    },
    {
      content: 'Operation Instruction',
      color: "#9932CC"
    },
    {
      content: 'Parts List',
      color: "#008000"
    },
  ];

  @ViewChild('chatContainer') private chatContainer!: ElementRef;
  @ViewChild('userMessageInput') private userMessageInput!: ElementRef;
  @ViewChild('messageContainer') set content(content: ElementRef) {
    if (content) {
      this.checkScrollPosition();
    }
  }

  isAtBottom = true;
  showScrollButton = false;
  isLoading = false;

  messages$ = this.chatbotService.messages.pipe(
    tap((messages) => {
      if (messages.length > 0) this.scrollToBottom();
    })
  );

  readonly PAGE_SEPARATOR = '@page';

  constructor(
    private store: Store<AppState>,
    public chatbotService: GlboalAiChatService,
    private readonly documentService: DocumentApiService,
    private readonly loadingIndicatorService: LoadingIndicatorService
  ) {
    this.store
      .select((state) => state.user.profile.displayname)
      .subscribe((name) => {
        this.userName = name;
      });

    this.messages = toSignal(
      this.store.select((state) => state.docugenAi.conversation.data.messages)
    );

    this.loadingStatus = toSignal(
      this.store.select((state) => state.docugenAi.conversation.status)
    );

    effect(() => {
      this.messages();
      this.loadingStatus();

      this.toScrollDown = true;
    });

    effect(() => {
      if (this.messages()) {
        requestAnimationFrame(() => this.checkScrollPosition());
      }
    });
  }

  @HostListener('click', ['$event'])
  handleClick(event: MouseEvent) {
    const target = event.target as HTMLAnchorElement;

    if (target.tagName === 'A' && target.className === 'chatbot-link') {
      event.preventDefault();

      const title = target.title;
      if (!title) {
        return;
      }

      const arr = title.split(this.PAGE_SEPARATOR);
      const filePath = arr[0];
      const pageNumber = arr[1] && arr[1].length > 0 ? arr[1] : undefined;
      this.onClickViewDocument(filePath, pageNumber);
    }
  }

  onClickViewDocument(documentUrl: string, pageNumber?: string): void {
    this.showLoadingIndicator(true);
    this.documentService
      .openResource(documentUrl)
      .pipe(
        finalize(() => {
          this.showLoadingIndicator(false);
        })
      )
      .subscribe(
        (data) => {
          const file = new Blob([data], { type: 'application/pdf' });
          const fileURL =
            URL.createObjectURL(file) +
            (pageNumber ? '#page=' + pageNumber : '');
          const newTab = window.open('', '_blank');
          if (newTab) {
            newTab.location.href = fileURL;
            newTab.onload = () => {
              URL.revokeObjectURL(fileURL); // Clean up after the PDF is opened
            };
          } else {
            console.error('Failed to open new tab');
          }
        },
        (error) => {
          console.error('Failed to fetch PDF resource', error);
        }
      );
  }

  showLoadingIndicator(show: boolean) {
    this.loadingIndicatorService.changeConfig({
      show: show,
      message: '',
    });
    this.isLoading = show;
  }

  ngAfterViewInit(): void {
    this.userMessageInput.nativeElement.focus();
    if (this.chatContainer) {
      this.checkScrollPosition();

      this.chatContainer.nativeElement.addEventListener('scroll', () => {
        requestAnimationFrame(() => this.checkScrollPosition());
      });

      window.addEventListener('resize', () => {
        requestAnimationFrame(() => this.checkScrollPosition());
      });
    }
  }

  private scrollToBottom(): void {
    setTimeout(() => {
      if (this.chatContainer) {
        this.chatContainer.nativeElement.scroll({
          top: this.chatContainer.nativeElement.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });
        requestAnimationFrame(() => this.checkScrollPosition());
      }
    }, 200);
  }

  // getMessagesFromLocalStorage(): string[] {
  //   let raw = localStorage.getItem('messages');
  //   return raw != null ? JSON.parse(raw) : [];
  // }

  send(message: string | null) {
    if (!message?.trim() || this.loadingStatus() === 'loading') {
      return;
    }

    this.chatbotService.sendUserMessage(message);

    this.typeUserMessage('');
  }

  onMinimizeButtonClick() {
    this.minimizeClicked.emit();
  }

  onCloseButtonClick() {
    this.chatbotService.clearChat();
    this.minimizeClicked.emit();
  }

  onChatButtonClicked(userMessage: string) {
    this.typeUserMessage(userMessage);
  }

  onOptionClicked(option: IUserOption) {
    if (this.loadingStatus() === 'loading') {
      return;
    }

    this.chatbotService.sendUserMessage(option.content);
  }

  ngAfterViewChecked(): void {
    if (this.toScrollDown) {
      this.scrollToBottom();
      this.toScrollDown = false;
    }
  }

  typeUserMessage(userMsg: string): void {
    this.userMessage.setValue(userMsg);
    this.userMessageInput.nativeElement.focus();
  }

  checkScrollPosition(): void {
    const element = this.chatContainer.nativeElement;
    const hasScroll = element.scrollHeight > element.clientHeight + 20;
    this.showScrollButton = hasScroll;

    if (hasScroll) {
      this.isAtBottom = Math.abs(
        element.scrollHeight - element.scrollTop - element.clientHeight
      ) < 50;
    } else {
      this.isAtBottom = true;
    }
  }

  formatResponse(message: any) {
    if (!message) {
      return '';
    }

    if (message.role === 'user') {
      return message.content;
    }

    const fileReferences = message.fileReferences;
    let content = message.content;
    if (fileReferences && fileReferences.length > 0) {
      fileReferences.forEach((ref: any) => {
        if (ref?.key) {
          const pageNumber = ref.pageNumber
            ? this.PAGE_SEPARATOR + ref.pageNumber
            : '';
          content = content.replaceAll(
            ref.key,
            ` <a class="chatbot-link" href="#" title="${ref.fileName}${pageNumber}">${ref.fileName}${pageNumber}</a>`
          );
        }
      });
    }
    return content;
  }
}
