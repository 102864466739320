
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorComponent } from '../../../components';
import { GenericErrorType } from '../generic-error-page/generic-error-page.types';

@Component({
    selector: 'app-system-down-error-page',
    imports: [TranslateModule, ErrorComponent],
    template: `<app-error
    [message]="'error.messages.500x' | translate"
    [allowHeaderFunctions]="false"
  ></app-error>`
})
export class SystemDownErrorPageComponent {
  GenericErrorType = GenericErrorType;
}
