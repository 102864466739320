import { InviteUser, User, UserStatus } from '../../../../../../shared/models';
import { UserGroupInfo } from './user-group.model';
import { createAction } from '@ngrx/store';


export interface DocugenaiUser extends User {
  groups: UserGroupInfo[];
}

export interface InviteDocugenaiUser extends InviteUser {
  userGroupIds: string[]
}

export interface UserStatusInfo {
  label: string;
  value: UserStatus;
}

export type UserStatusList = Array<UserStatusInfo>;

export type UserKeys = keyof DocugenaiUser;

export interface IUserWithExtendedProperties extends User {
  createdOn?: string;
  currentUser: boolean;
  statusStickerClass: string;
  statusStickerTooltip: string;
  statusStickerLabel: string;
  deletingAllowed: boolean;
  deleteIconTooltip: string;
  editingAllowed: boolean;
  editIconTooltip: string;
}

export const loadUsersWithGroups = createAction(
  `[User Group] Load users with associated groups`,
);
